export default {
	props: {
		productProp: {
			type: Object,
		},
	},
	computed: {
		eCommerceProduct() {
			return this.productProp || this.$store.state.ecommerce.product;
		},

		// TODO: This prop is only used one place in the project, related to swap in
		// EcommercePhonePriceOptions, so it can be removed once that SWAP logic is
		// removed. Hence not updated to use ProductCAtegroy enum for now. Also, let
		// us never ever accept a PR again where a variable named "type" is used to
		// represent "category" when type is also a thing ...
		//
		// Also: EcommercePhonePriceOptions seem to be unused now, but that must be
		// verified before deletion.
		eCommerceProductType() {
			return this.eCommerceProduct?.category === "Nettbrett" ? "Nettbrett" : "Mobil";
		},
	},
};
